<template>
  <exception-wrapper>
    <template slot="img">
      <img
        src="@/assets/images/svg/ic-404.svg"
        alt="404"
        width="305px"
        height="245px"
      />
    </template>
    <template slot="title">404 </template>
    <template slot="text">抱歉，你访问的页面不存在 </template>
  </exception-wrapper>
</template>

<script>
  import exceptionWrapper from './exceptionWrapper.vue';

  export default {
    name: 'exception404',
    components: { exceptionWrapper }
  };
</script>
